import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

export default function InstructionsSection({ errorMessage, status, type }) {
  const { t } = useTranslation();

  if (type === "boleto") {
    return null;
  }

  const isPendingOrError = status === "pending" || status === "error";

  return (
    <Fragment>
      {isPendingOrError && (
        <Fragment>
          <hr id="divider-checkout" className="mt-2" />
          {status === "error" && (
            <p className="checkout-tip">{t("CheckoutStatus.error.reason")}</p>
          )}
          <p className="checkout-description mb-0 pt-2 pb-2">
            {status === "error"
              ? t("CheckoutStatus.common.errorMessage")
              : t("CheckoutStatus.common.instructions")}
          </p>
          <hr id="divider-checkout" />
        </Fragment>
      )}
    </Fragment>
  );
}
