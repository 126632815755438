export function isInZigApp() {
  return window.location.host === process.env.REACT_APP_MOBILE_URL;
}

export function round2(number) {
  return Math.round((number + Number.EPSILON) * 100) / 100;
}

export function truncate(num, fixed) {
  var re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
  return parseFloat(num.toString().match(re)[0]);
}

export function groupBy(list, keyGetter) {
  const map = new Map();

  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });

  return map;
}

export function getSessionStorageItems(keys) {
  const checkoutResultStatus = {
    order_token: "token",
    order_value: "orderValue",
    "pix-code": "pix",
    "picpay-url": "picpayUrl",
    "picpay-code": "picpayCode",
    "error-message": "errorMessage",
    boleto: "boleto",
    slug: "slug",
    type: "type",
    tickets: "tickets",
    "payment_date": "paymentDate"
  };

  let items = {};

  keys.forEach((key) => {
    items[checkoutResultStatus[key]] = sessionStorage.getItem(key);
  });

  return items;
}

export function getBrowserName() {
  let browserInfo = navigator.userAgent;
  let browser;
  if (browserInfo.includes("Opera") || browserInfo.includes("Opr")) {
    browser = "Opera";
  } else if (browserInfo.includes("Edg")) {
    browser = "Edge";
  } else if (browserInfo.includes("Chrome")) {
    browser = "Chrome";
  } else if (browserInfo.includes("Safari")) {
    browser = "Safari";
  } else if (browserInfo.includes("Firefox")) {
    browser = "Firefox";
  } else {
    browser = "unknown";
  }
  return browser;
}

export async function getUserCoordinates() {
  if ("geolocation" in navigator) {
    try {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      const { latitude, longitude } = position.coords;

      document.cookie = `locationPermission=granted; max-age=5184000; path=/;`;

      return { latitude, longitude };
    } catch (error) {
      throw new Error("Erro ao obter coordenadas: " + error.message);
    }
  } else {
    throw new Error("Geolocalização não está disponível no seu navegador.");
  }
}

export function detectorOfUserDevice() {
  const userAgent = navigator.userAgent;
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent
    )
  ) {
    return "Dispositivo Móvel";
  } else if (/iPad/i.test(userAgent)) {
    return "iPad"; // Pode ser tratado de maneira especial, se necessário
  } else if (/Tablet|PlayBook/i.test(userAgent)) {
    return "Tablet";
  } else {
    return "Desktop";
  }
}

export function detectorOfAndroid() {
  const appVersion = navigator.appVersion.toLowerCase();

  if (appVersion.includes("android")) {
    return true;
  }

  return false;
}
export function detectorOfOS() {
  const platform = navigator.platform.toLowerCase();

  if (platform.includes("win")) {
    return "Windows";
  } else if (platform.includes("mac")) {
    return "macOS";
  } else if (platform.includes("linux")) {
    return "Linux";
  } else if (
    platform.includes("iphone") ||
    platform.includes("ipad") ||
    platform.includes("ipod")
  ) {
    return "iOS";
  } else if (platform.includes("android")) {
    return "Android";
  } else {
    return "Desconhecido";
  }
}
