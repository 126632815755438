/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import creditCardType from "credit-card-type";
import dayjs from "dayjs";
import { sift, sort } from "radash";
import React, { Fragment, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ReactPixel from "react-facebook-pixel";
import ReactGA4 from "react-ga4";
import { useForm } from "react-hook-form";
import MetaTags from "react-meta-tags";
import { useHistory, useParams } from "react-router-dom";
import { Element, animateScroll as scroll } from "react-scroll";
import Swal from "sweetalert2";
import * as yup from "yup";
import sendToLogger from "../../Helpers/errorLogger";
import MaskHelper from "../../Helpers/mask";
import { pushDataLayerAndEvent, pushGAEvent } from "../../Helpers/tagManager";
import {
  validateCnpj,
  validateCpf,
  validatePhone,
} from "../../Helpers/validations";

import shield_security from "../../assets/svg/shield_security.svg";
import CompleteUserRegistration from "../../components/Complete";
import Loader from "../../components/Loader";
import { useAuth } from "../../hooks/useAuth";
import CheckoutApi from "../../services/checkout-api";
import {
  findDescription,
  gatewayErrorMessagesByType,
  getDecodedCookie,
  setCookie,
} from "../../services/constants";
import KondutoService from "../../services/konduto";
import NewApi from "../../services/new-api";
import PagarMeService from "../../services/pagarme";
import PagseguroService from "../../services/pagseguro";
import { isInZigApp, round2 } from "../../services/utils";
import AcceptTermsView from "../../views/checkout/AcceptTermsView";
import CountDownView from "../../views/checkout/CountDownView";
import OrderSummaryView from "../../views/checkout/OrderSummaryView";
import TicketsSummaryView from "../../views/checkout/TicketsSummaryView";

import "./index.css";
import MoengageService from "../../services/moengage";
import { useTranslation } from "react-i18next";
import { useGateway } from "../../context/GatewayContext";
import { useLocation } from "../../hooks/useLocation";

import { TicketDetailView } from "../../views/checkout/TicketDetailView";
import { PaymentMethodsView } from "../../views/checkout/PaymentMethodsView";
import { BillingAddressView } from "../../views/checkout/BillingAddressView";
import { InstallmentsView } from "../../views/checkout/InstallmentsView";
import { CardPaymentFormView } from "../../views/checkout/PaymentFormView/CardPaymentFormView";
import { DiscountDescriptionView } from "../../views/checkout/DiscountDescriptionView";
import { useZendesk } from "../../hooks/useZendesk";
import NewLoading from "../../components/NewLoading";

const newApi = new NewApi();
const checkoutApi = new CheckoutApi();
const pagseguroService = new PagseguroService();

const CARD_METHODS = ["Cartão", "Débito", "Crédito"];
const ADDRESS_METHODS = ["Dinheiro", "PicPay", "Cortesia"];
const WALLET_METHODS = ["Google Pay", "Apple Pay"];
const YUNO_METHODS = ["Cartão", "Débito", "Crédito", "Google Pay", "Apple Pay"];

const yunoPaymentTypes = {
  Cartão: "CARD",
  Débito: "CARD",
  PIX: "PIX",
  Boleto: "BOLETO",
  "Google Pay": "GOOGLE_PAY",
  "Apple Pay": "APPLE_PAY",
};

const currencyDictionary = {
  BRL: "brl",
  USD: "usd",
  EUR: "eur",
};

const invalidCharacters = /^[^\d=@]+$/;

const accentuationAndHifen =
  /^(?:[a-zA-Z\u00C0-\u017F]+(?:[-\s](?:[a-zA-Z\u00C0-\u017F]+))*)$/;

const CheckoutNew = () => {
  const { yuno, stripe } = useGateway();

  const history = useHistory();
  const { token } = useParams();
  const { isLogged, user, isCompletedRegister } = useAuth();
  const { t, i18n } = useTranslation();
  const { hide } = useZendesk();
  const { handleIPLocation } = useLocation();

  const userData = JSON.parse(localStorage.getItem("userData") || "{}");

  const defaultPaymentMethod = "PIX";

  const schema = yup.object().shape({
    attendees: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.number(),
          email: yup
            .string()
            .required(t("Checkout.emailRequired"))
            .email(t("Checkout.emailInvalid"))
            .matches(
              // eslint-disable-next-line no-control-regex
              /^(?!(?:(?:\x22?\x5C[\x00-\x7E]\x22?)|(?:\x22?[^\x5C\x22]\x22?)){255,})(?!(?:(?:\x22?\x5C[\x00-\x7E]\x22?)|(?:\x22?[^\x5C\x22]\x22?)){65,}@)(?:(?:[\x21\x23-\x27\x2A\x2B\x2D\x2F-\x39\x3D\x3F\x5E-\x7E]+)|(?:\x22(?:[\x01-\x08\x0B\x0C\x0E-\x1F\x21\x23-\x5B\x5D-\x7F]|(?:\x5C[\x00-\x7F]))*\x22))(?:\.(?:(?:[\x21\x23-\x27\x2A\x2B\x2D\x2F-\x39\x3D\x3F\x5E-\x7E]+)|(?:\x22(?:[\x01-\x08\x0B\x0C\x0E-\x1F\x21\x23-\x5B\x5D-\x7F]|(?:\x5C[\x00-\x7F]))*\x22)))*@(?:(?:(?!.*[^.]{64,})(?:(?:(?:xn--)?[a-z0-9]+(?:-[a-z0-9]+)*\.){1,126}){1,}(?:(?:[a-z][a-z0-9]*)|(?:(?:xn--)[a-z0-9]+))(?:-[a-z0-9]+)*)|(?:\[(?:(?:IPv6:(?:(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){7})|(?:(?!(?:.*[a-f0-9][:\]]){7,})(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){0,5})?::(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){0,5})?)))|(?:(?:IPv6:(?:(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){5}:)|(?:(?!(?:.*[a-f0-9]:){5,})(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){0,3})?::(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){0,3}:)?)))?(?:(?:25[0-5])|(?:2[0-4][0-9])|(?:1[0-9]{2})|(?:[1-9]?[0-9]))(?:\.(?:(?:25[0-5])|(?:2[0-4][0-9])|(?:1[0-9]{2})|(?:[1-9]?[0-9]))){3}))\]))$/,
              t("Checkout.emailInvalid")
            )
            .test(
              "unique",
              t("Checkout.duplicateAttendeeEmail"),
              (value, { from }) =>
                from[1].value.autofill_enabled ||
                from[1].value.attendees.filter(({ email }) => email === value)
                  .length === 1
            ),
          first_name: yup
            .string()
            .required(t("CommonExpressions.nameRequired"))
            .matches(
              invalidCharacters,
              t("CommonExpressions.nameInvalidCharacters")
            )
            .matches(
              accentuationAndHifen,
              t("CommonExpressions.typedCorrectly")
            )
            .min(3, t("CommonExpressions.nameInvalidLength"))
            .trim(),
          last_name: yup
            .string()
            .required(t("CommonExpressions.lastnameRequired"))
            .matches(
              invalidCharacters,
              t("CommonExpressions.lastnameInvalidCharacters")
            )
            .matches(
              accentuationAndHifen,
              t("CommonExpressions.typedCorrectly")
            )
            .min(3, t("CommonExpressions.lastnameInvalidLength"))

            .trim(),
          automatic_fill: yup.boolean().nullable(),
          passport_name: yup.string().nullable(),
          passport_index: yup.number().nullable(),
          seat_group_name: yup.string().nullable(),
          seat_id: yup.string().nullable(),
          seat_name: yup.string().nullable(),
          ticket: yup.string(),
          use_my_data: yup.boolean().nullable(),
          ticket_type_id: yup.number(),
          answers: yup
            .array()
            .nullable()
            .of(
              yup.object().shape({
                answer: yup
                  .string()
                  .when("required", {
                    is: 1,
                    then: (schema) =>
                      schema.required(t("Checkout.fieldRequired")),
                  })
                  .transform((value, originalValue) => {
                    if (typeof value === "string") {
                      return value;
                    }

                    return originalValue?.length
                      ? JSON.stringify(originalValue)
                      : "";
                  }),
                required: yup.number(),
                ticket_id: yup.number(),
                event_form_id: yup.number(),
              })
            ),
        })
      )
      .default([]),
    payment: yup.object().shape({
      phone: yup
        .string()
        .nullable()
        .when("payment_mode", {
          is: () => !isZig() && !isStripe(),
          then: (schema) =>
            schema
              .required(t("Checkout.phoneRequired"))
              .test("validate-phone", t("Checkout.phoneInvalid"), (value) =>
                validatePhone(value)
              ),
        }),
      document: yup
        .string()
        .nullable()
        .when("payment_mode", {
          is: () => !isZig() && !isStripe(),
          then: (schema) =>
            schema
              .required(t("Checkout.documentRequired"))
              .test(
                "validate-document",
                t("Checkout.documentInvalid"),
                (document) => validateCpf(document) || validateCnpj(document)
              ),
        }),

      payment_type: yup.string(),
      payment_mode: yup.string().nullable(),
      card_cvv: yup
        .string()
        .nullable()
        .when("payment_type", {
          is: (type) => CARD_METHODS.includes(type) && !isZig() && !isStripe(),
          then: (schema) => schema.required(t("Checkout.codeRequired")),
        }),
      card_number: yup
        .string()
        .nullable()
        .when("payment_type", {
          is: (type) => CARD_METHODS.includes(type) && !isZig() && !isStripe(),
          then: (schema) => schema.required(t("Checkout.numberRequired")),
        }),
      card_holder: yup
        .string()
        .nullable()
        .when("payment_type", {
          is: (type) => CARD_METHODS.includes(type) && !isZig() && !isStripe(),
          then: (schema) => schema.required(t("Checkout.printedNameRequired")),
        }),
      card_expiration: yup
        .string()
        .nullable()
        .when("payment_type", {
          is: (type) => CARD_METHODS.includes(type) && !isZig() && !isStripe(),
          then: (schema) => schema.required(t("Checkout.expirationRequired")),
        }),
      installments: yup.number(),
      zip_code: yup
        .string()
        .nullable()
        .when("payment_type", {
          is: (type) =>
            !ADDRESS_METHODS.concat(WALLET_METHODS).includes(type) &&
            !isStripe(),
          then: (schema) => schema.required(t("Checkout.zipcodeRequired")),
        }),
      city: yup
        .string()
        .nullable()
        .when("payment_type", {
          is: (type) =>
            !ADDRESS_METHODS.concat(WALLET_METHODS).includes(type) &&
            !isStripe(),
          then: (schema) => schema.required(t("Checkout.cityRequired")),
        }),
      neighborhood: yup
        .string()
        .nullable()
        .when("payment_type", {
          is: (type) =>
            !ADDRESS_METHODS.concat(WALLET_METHODS).includes(type) &&
            !isStripe(),
          then: (schema) => schema.required(t("Checkout.neighborhoodRequired")),
        }),
      number: yup
        .string()
        .nullable()
        .when("payment_type", {
          is: (type) =>
            !ADDRESS_METHODS.concat(WALLET_METHODS).includes(type) &&
            !isStripe(),
          then: (schema) => schema.required(t("Checkout.numberRequired")),
        }),
      state: yup
        .string()
        .nullable()
        .when("payment_type", {
          is: (type) =>
            !ADDRESS_METHODS.concat(WALLET_METHODS).includes(type) &&
            !isStripe(),
          then: (schema) => schema.required(t("Checkout.stateRequired")),
        }),
      street: yup
        .string()
        .nullable()
        .when("payment_type", {
          is: (type) =>
            !ADDRESS_METHODS.concat(WALLET_METHODS).includes(type) &&
            !isStripe(),
          then: (schema) => schema.required(t("Checkout.addressRequired")),
        }),
      complement: yup.string().nullable(),
    }),
    autofill_enabled: yup.boolean().default(true),
  });

  const {
    watch,
    register,
    setValue,
    clearErrors,
    handleSubmit,
    trigger,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: retrieveCookieData(),
  });

  const [data, setData] = useState();
  const [event, setEvent] = useState();
  const [cardCvv, setCardCvv] = useState(3);
  const [cardType, setCardType] = useState();
  const [orderData, setOrderData] = useState();
  const [intentInfo, setIntentInfo] = useState({ session: "" });
  const [loadingPage, setLoadingPage] = useState(true);
  const [acceptTerms, setAcceptTerms] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [installments, setInstallments] = useState([]);
  const [formQuestions, setFormQuestions] = useState();
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [zipCodeFetched, setZipCodeFetched] = useState(false);
  const [fetchingZipCode, setFetchingZipCode] = useState(false);
  const [isYunoInitialized, setIsYunoInitialized] = useState(false);
  const [initializingYuno, setInitializingYuno] = useState(false);
  const [stripeIsInitialized, setStripeIsInitialized] = useState(false);
  const [initializingStripe, setInitializingStripe] = useState(false);
  const [stripeElements, setStripeElements] = useState();
  const [stripeConsumer, setStripeConsumer] = useState();

  // eslint-disable-next-line no-unused-vars
  const [discountCoupon, setDiscountCoupon] = useState(() => {
    const discountCode = localStorage.getItem("code");
    if (discountCode) {
      return discountCode;
    }
    return null;
  });

  const isCodeAppliedSameAsIntegration = event?.discount?.some(
    (discount) => discount.code === discountCoupon
  );

  const showInformativeBox =
    event?.has_integration && isCodeAppliedSameAsIntegration;

  const isUserElegibleForDiscount = data?.discount_details?.by_integration;

  const integrationCouponDescription = findDescription(
    discountCoupon,
    event?.discount
  );

  const isZig = () => {
    const paymentMode = watch("payment.payment_mode");
    const gateway = event?.gateway || event?.default_acquirer;

    if (!paymentMode) {
      return gateway === "zig" ? true : false;
    } else {
      return paymentMode === "zig" ? true : false;
    }
  };

  const isStripe = () => {
    const paymentMode = watch("payment.payment_mode");
    const gateway = event?.gateway || event?.default_acquirer;

    if (!paymentMode) {
      return gateway === "stripe";
    } else {
      return paymentMode === "stripe";
    }
  };

  const hasAtLeastOneZigPayment = () => {
    return (
      isZig() || data.methods.some(({ payment_mode }) => payment_mode === "zig")
    );
  };

  const hasAtLeastOneStripePayment = (data) => {
    return (
      isStripe() ||
      data.methods.some(({ payment_mode }) => payment_mode === "stripe")
    );
  };

  useEffect(() => {
    const cartPromise = newApi
      .get(`orders/${token}`)
      .then(async ({ data }) => {
        if (data.expired) {
          Swal.fire({
            icon: "error",
            title: t("Checkout.timeout"),
            text: t("Checkout.timeoutMessage"),
            didClose: () => {
              localStorage.removeItem("order_token");
              history.push(
                `/eventos/${localStorage.getItem("slug") || data.event_slug}`
              );
            },
          });

          return;
        }

        const eventInfo = await newApi
          .get(`events/${localStorage.getItem("slug") || data.event_slug}`)
          .then(({ data }) => {
            setEvent(data);

            if (data.g_analytics_code) {
              ReactGA4.gtag("config", data.g_analytics_code);
            }

            return data;
          });

        const attendees = [];
        const questions = sort(
          data.custom_fields,
          ({ order_field }) => order_field
        );

        const firstMethod =
          data.methods.find(
            (method) => method.method === defaultPaymentMethod
          ) || data.methods[0];

        setData(data);

        setOrderData(firstMethod);
        setFormQuestions(questions);

        setValue(
          "autofill_enabled",
          !!eventInfo.enable_attendees_checkout_autofill
        );

        if (!watch("attendees")) {
          firstMethod.tickets.forEach((item, index) => {
            attendees.push({
              id: item.id,
              ticket: item.name,
              first_name:
                item.passport_id || index === 0 ? user.first_name : "",
              last_name: item.passport_id || index === 0 ? user.last_name : "",
              email: item.passport_id || index === 0 ? user.email : "",
              passport_name: item.passport_name,
              seat_name: item.seat_name,
              seat_group_name: item.seat_group_name,
              seat_id: item.seat_id,
              ticket_type_id: item.ticket_type_id,
              automatic_fill: !!item.passport_id || index === 0,
              use_my_data: !!item.passport_id || index === 0,
              passport_index: item.passport_index,
              answers: questions.map(({ id, required, field_type }) => ({
                answer: "",
                required: required,
                ticket_id: item.id,
                event_form_id: id,
              })),
            });
          });

          setValue("attendees", attendees);
        }

        if (!watch("payment.installments")) {
          setValue("payment.installments", 1);
        }

        if (data.methods.length === 1 && firstMethod.value === 0) {
          setValue("payment.payment_type", "Cortesia");
        } else if (!watch("payment.payment_type")) {
          setValue("payment.payment_type", firstMethod.method);
          setValue("payment.payment_mode", firstMethod.payment_mode);
        }

        if (data.fees) {
          const processingFee =
            data.methods.find(({ processing_fee }) => !!processing_fee)
              ?.processing_fee || 0;

          const maxInstallments = Object.keys(data.fees.installments).length;
          const installments = [];
          const orderTotal = firstMethod.value + processingFee / 100.0;

          for (let index = 0; index < maxInstallments; index++) {
            let total = 0;

            if (data.fees.type === "composto") {
              total = round2(
                orderTotal *
                (1 +
                  (data.fees.installments[(index + 1).toString()] / 100) *
                  Math.max(index, 1))
              );
            } else {
              total =
                orderTotal *
                (1 + data.fees.installments[(index + 1).toString()] / 100);
            }

            installments.push({
              total,
              installment: round2(total / (index + 1)),
            });
          }

          setInstallments(installments);
        }

        pushGAEvent("begin_checkout", {
          currency: "BRL",
          value: firstMethod?.value,
          coupon: data.discount_code,
          items: firstMethod.tickets.map((item) => ({
            item_id: item.id,
            item_name: item.name,
            price: item.value + item.fee,
            quantity: 1,
          })),
        });

        if (user) {
          await MoengageService.createEvent({
            type: "event",
            customer_id: user?.id,
            actions: [
              {
                action: "Begin Checkout",
                attributes: {
                  currency: "BRL",
                  value: firstMethod?.value,
                  coupon: data.discount_code,
                  items: firstMethod.tickets.map((item) => ({
                    item_id: item.id,
                    item_name: item.name,
                    price: item.value + item.fee,
                    quantity: 1,
                  })),
                },
                platform: "web",
              },
            ],
          });
        }
      })
      .catch((error) => {
        sendToLogger(error);

        Swal.fire({
          icon: "error",
          title: t("Checkout.orderNotFound"),
          text: t("Checkout.orderNotFoundMessage"),
          didClose: () => {
            history.push(
              `/eventos/${localStorage.getItem("slug") || data.event_slug}`
            );
          },
        });
      });

    const scriptPromise = pagseguroService.loadScript();

    Promise.all([cartPromise, scriptPromise]).finally(() => {
      setLoadingPage(false);
    });

    KondutoService.sendEvent("page", "checkout");
  }, [token]);

  useEffect(() => {
    if (!watch() || !data) {
      return;
    }

    const cookieData = getDecodedCookie("checkoutInfo", token);

    if (!cookieData) {
      setCookie("checkoutInfo", { code: token }, 900);
      return;
    }

    const updatedCookieData = {
      ...cookieData,
      ...watch(),
    };

    setCookie("checkoutInfo", updatedCookieData, data.time_to_expire);

    const cardNumber = watch("payment.card_number");
    const paymentType = watch("payment.payment_type");

    if (!!cardNumber) {
      const cards = creditCardType(cardNumber);

      if (cards.length) {
        setCardType(cards[0].type);
        setCardCvv(cards[0].code.size);
      } else {
        setCardType(null);
        setCardCvv(3);
      }
    } else {
      setCardType(null);
      setCardCvv(3);
    }

    if (!!paymentType) {
      const findMethod = data.methods.find(
        ({ method }) => method === paymentType
      );
      if (findMethod) {
        setOrderData(findMethod);
      }
    }
  }, [watch()]);

  useEffect(() => {
    async function sendPixelAndADS() {
      if (event?.fb_pixel_id) {
        await trackingEventToPixel("AddPaymentInfo");
      }

      if (event?.g_id_ads) {
        ReactGA4.gtag("config", event.g_id_ads);
      }
    }

    sendPixelAndADS();
  }, [event]);

  useEffect(() => {
    if (!loadingPage && data) {
      scroll.scrollToTop();
    }
  }, [loadingPage, data]);

  useEffect(() => {
    const initializeYuno = async () => {
      if (
        !isYunoInitialized &&
        data &&
        token &&
        orderData &&
        orderData.value > 0 &&
        isCompletedRegister &&
        hasAtLeastOneZigPayment()
      ) {
        setInitializingYuno(true);
        await initCheckoutLite();
        if (CARD_METHODS.includes(watch("payment.payment_type"))) {
          mountYunoCheckout();
        }
      }
    };

    initializeYuno();
  }, [data, token, isYunoInitialized, isCompletedRegister, isZig]);

  useEffect(() => {
    const initializeStripe = async () => {
      if (
        !initializingStripe &&
        !stripeIsInitialized &&
        !intentInfo.session.length &&
        orderData &&
        orderData.value > 0 &&
        data
      ) {
        if (hasAtLeastOneStripePayment(data)) {
          setInitializingStripe(true);
          await createStripeIntent(data);
        }
      }
    };

    initializeStripe();
  }, [data, stripeIsInitialized, intentInfo, isStripe]);

  useEffect(() => {
    if (isYunoInitialized) {
      setInitializingYuno(true);
      yuno.unmount();
      yuno.unmountSdk();
      initCheckoutLite().then(() => {
        mountYunoCheckout();
      });
    }
  }, [i18n.language]);

  useEffect(() => {
    async function handleUserData() {
      if (Object.keys(userData).length === 0) {
        await handleIPLocation();
      }
    }

    if (sessionStorage.getItem("newCheckoutSession")) {
      const currentCheckoutSession =
        sessionStorage.getItem("newCheckoutSession");
      sessionStorage.clear();
      sessionStorage.setItem("newCheckoutSession", currentCheckoutSession);
    } else {
      sessionStorage.clear();
    }

    handleUserData();
  }, []);

  useEffect(() => {
    hide();
    sessionStorage.clear();
  }, []);

  function retrieveCookieData() {
    const data = getDecodedCookie("checkoutInfo", token);

    if (data) {
      const autofill =
        data.autofill_enabled === undefined ? true : data.autofill_enabled;

      return { ...data, autofill_enabled: autofill };
    } else {
      return {};
    }
  }

  async function finalizeCardByTimeOut() {
    pushGAEvent("timeout_checkout");

    if (user) {
      await MoengageService.createEvent({
        type: "event",
        customer_id: user.id,
        actions: [
          {
            action: "Timeout Checkout",
            platform: "web",
          },
        ],
      });
    }

    Swal.fire({
      icon: "error",
      title: t("Checkout.timeout"),
      text: t("Checkout.timeoutMessage"),
      didClose: () => {
        history.push(`/eventos/${localStorage.getItem("slug")}`);
      },
    });
  }

  function triggerEventGA4AndADS(value, eventType) {
    const orderInfo = data.methods.find(
      ({ method }) => method === watch("payment.payment_type")
    );

    pushDataLayerAndEvent({
      tkt_event_name: event.name,
      event_local: event.event_location.name,
      event_produced_by: event.producer.name,
      event_date: dayjs(data.start_date).format("DD/MM/YYYY"),
      user_id: user.id,
      is_buyer: user.is_buyer,
      active_buyer: user.active_buyer,
      last_order_ago: user.last_order_ago,
    });

    pushGAEvent(eventType, {
      tax: orderInfo.tickets.reduce((acc, value) => acc + value.fee, 0),
      value: orderInfo.value,
      coupon: data.discount_code,
      currency: "BRL",
      transaction_id: data.token,
      items: orderInfo.tickets.map((item) => ({
        item_id: item.id,
        item_name: item.name,
        price: (item.value + item.fee).toFixed(2),
        quantity: 1,
      })),
    });

    if (event.g_id_ads && event.g_conversion_snippet_ads) {
      pushGAEvent("conversion", {
        send_to: `${event.g_id_ads}/${event.g_conversion_snippet_ads}`,
        value,
        currency: "BRL",
      });
    }
  }

  async function sendData(
    formData,
    oneTimeToken,
    tokenWithInformation,
    session,
    intentId,
    intentSecret
  ) {
    setLoadingPayment(true);
    const { payment, attendees } = formData;
    const checkoutSession =
      sessionStorage.getItem("newCheckoutSession") || session;

    const orderInfo = data.methods.find(
      ({ method }) => method === payment.payment_type
    );

    const handleZigPaymentType = () => {
      return tokenWithInformation.card_data.type === "CREDIT"
        ? "Cartão"
        : "Débito";
    };

    if (!isLogged) {
      setLoadingPayment(false);
      errorMessage(t("Checkout.userNotLoggedIn"));
    }

    if (
      isZig() &&
      tokenWithInformation &&
      orderInfo.value > 0 &&
      handleZigPaymentType() !== payment.payment_type
    ) {
      errorMessage(t("Checkout.paymentTypeMismatched"));
      setLoadingPayment(false);
      return;
    }

    pushGAEvent("add_payment_info", {
      currency: "BRL",
      value: orderInfo.value,
      coupon: data.discount_code,
      payment_type: payment.payment_type,
      items: orderInfo.tickets.map((item) => ({
        item_id: item.id,
        item_name: item.name,
        price: (item.value + item.fee).toFixed(2),
        quantity: 1,
      })),
    });

    if (user) {
      await MoengageService.createEvent({
        type: "event",
        customer_id: user.id,
        actions: [
          {
            action: "Add payment Info",
            attributes: {
              currency: "BRL",
              value: orderInfo.value,
              coupon: data.discount_code,
              payment_type: payment.payment_type,
              items: orderInfo.tickets.map((item) => ({
                item_id: item.id,
                item_name: item.name,
                price: (item.value + item.fee).toFixed(2),
                quantity: 1,
              })),
            },
            platform: "web",
          },
        ],
      });
    }

    pushDataLayerAndEvent({
      currency: "BRL",
      value: orderInfo.value,
      payment_type: payment.payment_type,
      items: orderInfo.tickets,
      transaction_id: data.token,
      tax: orderInfo.tickets.reduce((acc, value) => acc + value.fee, 0),
      tkt_event_name: event.name,
      event_local: event.event_location.name,
      event_produced_by: event.producer.name,
      coupon: data.discount_code,
    });

    const address = {
      city: payment.city,
      state: payment.state,
      street: payment.street,
      number: payment.number,
      zip_code: payment.zip_code,
      complement: payment.complement,
      neighborhood: payment.neighborhood,
    };

    const attendeeInfos = [...attendees].map((item) => {
      (item.answers || []).forEach((answer) => {
        try {
          answer.answer = sift(JSON.parse(answer.answer));
        } catch {
          answer.answer = sift([answer.answer]);
        }
      });

      return item;
    });

    const object = {
      token: token,
      phone: MaskHelper.numberMask(payment.phone),
      address,
      event_id: event.id,
      attendees: attendeeInfos,
      document: MaskHelper.numberMask(payment.document),
      seller_name: process.env.REACT_APP_CHECKOUT_SELLER_NAME,
      installments: payment.installments,
      payment_method:
        isZig() && orderInfo.value > 0
          ? handleZigPaymentType()
          : payment.payment_type,
      card_data: {},
    };

    if (isZig() && YUNO_METHODS.includes(payment.payment_type)) {
      object.redirect_url = `${window.location.protocol}//${window.location.host}/processing?token=${token}`;
      object.checkout_session = checkoutSession;
      object.nonce = oneTimeToken;

      if (CARD_METHODS.includes(payment.payment_type)) {
        if (tokenWithInformation.customer.phone) {
          object.phone =
            tokenWithInformation.customer.phone.country_code +
            tokenWithInformation.customer.phone.number;
        }

        object.document =
          tokenWithInformation.customer.document.document_number;
        object.card_data = {
          id: tokenWithInformation.token,
          holder: tokenWithInformation.card_data.holder_name,
          brand: tokenWithInformation.card_data.brand,
          bin: tokenWithInformation.card_data.iin.slice(0, -2),
        };
      }
    } else if (CARD_METHODS.includes(payment.payment_type)) {
      try {
        if (data.acquirer === "pagseguro") {
          const service = new PagseguroService();

          const threeDSMethod =
            payment.payment_type === "Cartão" ? "CREDIT_CARD" : "DEBIT_CARD";

          const cardToken = await service.cardTokenizer(
            payment.card_number,
            payment.card_holder,
            payment.card_cvv,
            payment.card_expiration
          );
          object.card_data = { id: cardToken, holder: payment.card_holder };

          let enableThreeds = false;
          let forceThreedsAuthentication = false;

          if (threeDSMethod === "CREDIT_CARD") {
            const { data } = await checkoutApi.post("/antifraud/verify-user", {
              token,
              seller_name: object.seller_name,
            });

            enableThreeds = data.force_threeds;
            forceThreedsAuthentication = data.authenticated_threeds;
          }

          if (threeDSMethod === "DEBIT_CARD" || enableThreeds) {
            const threedsResult = await callThreedsService(
              threeDSMethod,
              cardToken,
              user,
              forceThreedsAuthentication,
              payment.installments,
              payment.phone,
              address
            );

            if (!threedsResult.success) {
              setIsSubmitting(false);
              setLoadingPayment(false);

              return;
            }

            object.card_data.threeds = {
              session: threedsResult.session,
              three_secure_id: threedsResult.id,
              three_secure_auth:
                threedsResult.authenticationStatus === "AUTHENTICATED",
            };
          }
        } else if (data.acquirer === "pagarme") {
          const service = new PagarMeService();
          const expirationInfos = payment.card_expiration.split("/");
          const expiration = expirationInfos[0] + expirationInfos[1];

          const cardToken = await service.cardTokenizer(
            payment.card_number,
            payment.card_holder,
            payment.card_cvv,
            expiration
          );

          object.card_data = { id: cardToken, holder: payment.card_holder };
        } else if (data.acquirer === "stripe") {
          object.transaction_id = intentId;
          object.event_id = event.id;
          object.secret = intentSecret;
        }
      } catch (err) {
        sendToLogger(err);
        setLoadingPayment(false);
        setIsSubmitting(false);
        errorMessage(gatewayErrorMessagesByType("card_error"));

        return;
      }
    }

    setInitializingYuno(false);

    if (isStripe() && orderInfo.value > 0) {
      checkoutApi
        .post("/finish", object, {
          Headers: { "Access-Control-Allow-Origin": "*" },
        })
        .then((res) => {
          sessionStorage.setItem("tickets", JSON.stringify(res.data));
          sessionStorage.setItem("order_token", token);
          history.push(`/status/${token}/checkout/concluded`);
        })
        .catch(async (err) => {
          sendToLogger(err);
          setLoadingPayment(false);
          setIsSubmitting(false);
          await handlePaymentErrors(err);
        });
    } else {
      checkoutApi
        .post(`/pay`, object, {
          headers: { "Access-Control-Allow-Origin": "*" },
        })
        .then(async ({ data }) => {
          await trackingEventToPixel(
            "Purchase",
            { value: orderInfo.value, currency: "BRL" },
            0
          );

          const getEventType = (paymentType) => {
            const eventType = {
              PIX: "purchase_pix_pending",
              PicPay: "purchase_picpay_pending",
            };
            return eventType[paymentType] || "purchase";
          };

          triggerEventGA4AndADS(
            orderInfo.value,
            getEventType(payment.payment_type)
          );

          if (user) {
            await MoengageService.createEvent({
              type: "event",
              customer_id: user.id,
              actions: [
                {
                  action: "Purchase",
                  attributes: {
                    tax: orderInfo.tickets.reduce(
                      (acc, value) => acc + value.fee,
                      0
                    ),
                    value: orderInfo.value,
                    coupon: data.discount_code,
                    currency: "BRL",
                    transaction_id: data.token,
                    items: orderInfo.tickets.map((item) => ({
                      item_id: item.id,
                      item_name: item.name,
                      price: (item.value + item.fee).toFixed(2),
                      quantity: 1,
                    })),
                  },
                  platform: "web",
                },
              ],
            });
          }

          sessionStorage.setItem("tickets", JSON.stringify(data));
          sessionStorage.setItem("order_token", token);
          sessionStorage.removeItem("seatsio");

          if (user) {
            await MoengageService.createEvent({
              type: "event",
              customer_id: user.id,
              actions: [
                {
                  action: "End order checkout",
                  platform: "web",
                },
              ],
            });
          }
          sessionStorage.removeItem("newCheckoutSession");

          if (isZig() && orderInfo.value > 0) {
            const status = await yuno.yunoPaymentResult(checkoutSession);

            await yuno.continuePayment();

            if (!data.is_3ds) {
              if (status === "PENDING") {
                history.push(`/status/${token}/checkout/processing`);
              } else if (status === "SUCCEEDED") {
                history.push(`/status/${token}/checkout/concluded`);
              } else {
                history.push(`/status/${token}/checkout/error`);
              }
            }
          } else if (payment.payment_type === "PicPay") {
            sessionStorage.setItem("picpay-code", data.code);
            sessionStorage.setItem("type", "picpay");
            sessionStorage.setItem("picpay-url", data.url);
            sessionStorage.setItem("order_value", orderInfo.value);
            sessionStorage.setItem("payment_date", JSON.stringify(new Date()));
            history.push(`/status/${token}/checkout/pending`);
          } else if (payment.payment_type === "PIX") {
            sessionStorage.setItem("pix-code", data.code);
            sessionStorage.setItem("type", "pix");
            sessionStorage.setItem("order_value", orderInfo.value);
            sessionStorage.setItem("payment_date", JSON.stringify(new Date()));
            history.push(`/status/${token}/checkout/pending`);
          } else if (payment.payment_type === "Boleto") {
            sessionStorage.setItem("boleto", data.url);
            sessionStorage.setItem("type", "boleto");
            history.push(`/status/${token}/checkout/pending`);
          } else {
            if (data.status === "pré-autorizada") {
              history.push(`/status/${token}/checkout/processing`);
            } else if (data.status === "falhada") {
              history.push(`/status/${token}/checkout/error`);
            } else {
              history.push(`/status/${token}/checkout/concluded`);
            }
          }
        })
        .catch(async (err) => {
          sendToLogger(err);
          setIsSubmitting(false);
          await handlePaymentErrors(err);
          setLoadingPayment(false);
        })
        .finally(() => {
          setLoadingPayment(false);
          setInitializingYuno(false);
        });
    }
  }

  async function callThreedsService(
    paymentMethod,
    cardToken,
    user,
    forceAuthentication,
    installment,
    phone,
    address
  ) {
    let session;

    try {
      session = await pagseguroService.buildSession(event.id);
    } catch (err) {
      sendToLogger(err);
      setLoadingPayment(false);
      setIsSubmitting(false);
      errorMessage(gatewayErrorMessagesByType("service_request_timeout"));

      return { success: false };
    }

    try {
      let requiredChallenge = false;

      const { id, status, authenticationStatus } =
        await pagseguroService.threeDSecure(
          {
            name: `${user.first_name} ${user.last_name}`,
            email: user.email,
            phone,
          },
          address,
          {
            type: paymentMethod,
            amount:
              (installment > 1 || paymentMethod === "CREDIT_CARD"
                ? installments[installment - 1].total.toFixed(2)
                : orderData.value) * 100,
            cardToken,
            installments: installment,
          },
          () => {
            requiredChallenge = true;
          }
        );

      if (authenticationStatus !== "AUTHENTICATED" && requiredChallenge) {
        setIsSubmitting(false);
        setLoadingPayment(false);
        errorMessage(gatewayErrorMessagesByType("3ds_challenge_failure"));
        return { success: false };
      }

      if (paymentMethod === "DEBIT_CARD" && status === "AUTH_NOT_SUPPORTED") {
        setIsSubmitting(false);
        setLoadingPayment(false);
        errorMessage(gatewayErrorMessagesByType("3ds_debit_failure"));
        return { success: false };
      }

      if (
        status === "CHANGE_PAYMENT_METHOD" ||
        status === "AUTH_NOT_SUPPORTED"
      ) {
        setIsSubmitting(false);
        setLoadingPayment(false);
        errorMessage(gatewayErrorMessagesByType("3ds_change_failure"));
        return { success: false };
      }

      if (
        paymentMethod === "CREDIT_CARD" &&
        forceAuthentication &&
        authenticationStatus !== "AUTHENTICATED"
      ) {
        setIsSubmitting(false);
        setLoadingPayment(false);
        errorMessage(gatewayErrorMessagesByType("3ds_failure"));
        return { success: false };
      }

      return { success: true, id, session, authenticationStatus };
    } catch (err) {
      sendToLogger(err);
      setIsSubmitting(false);
      setLoadingPayment(false);
      errorMessage(gatewayErrorMessagesByType("3ds_failure"));
    }

    return { success: false };
  }

  function errorMessage(message, onDidClose) {
    Swal.fire({
      icon: "error",
      title: t("CommonExpressions.ops"),
      text: message,
      didClose: onDidClose,
    });
  }

  async function handlePaymentErrors(error) {
    if (!error?.response?.data) {
      errorMessage(t("Checkout.purchaseFailure"));
      return;
    }
    const errorData = error.response.data;

    if (errorData.retry) {
      if (errorData.max_attempts_reached) {
        Swal.fire({
          icon: "error",
          title: t("CommonExpressions.ops"),
          html: `${errorData.message}<br/><br/>${t(
            "Checkout.maxAttemptsReached"
          )}<br/><br/>${t("Checkout.maxAttemptsReachedMessage")}`,
          didClose: async () => {
            setValue("payment.payment_type", data.methods[0].method);
            window.location.reload();
          },
        });
      } else {
        if (isZig()) {
          await yunoErrorHandler();
        }

        errorMessage(errorData.message);
      }
    } else if (errorData.code === "expired_order") {
      finalizeCardByTimeOut();
    } else if (errorData.code === "not_found") {
      Swal.fire({
        icon: "error",
        title: t("CommonExpressions.ops"),
        text: t("Checkout.errorRedirect"),
        didClose: () => {
          history.push(`/eventos/${localStorage.getItem("slug")}`);
        },
      });
    } else {
      history.push(`/status/${token}/checkout/error`);
    }
  }

  function trackingEventToPixel(title, data, timeout = 3000) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (event?.fb_pixel_id) {
          ReactPixel.trackSingle(event.fb_pixel_id, title, data);
        }

        if (process.env.REACT_APP_PIXEL_ID) {
          ReactPixel.trackSingle(process.env.REACT_APP_PIXEL_ID, title, data);
        }

        resolve();
      }, timeout);
    });
  }

  async function formHandlerSubmit(formData) {
    if (isStripe() && orderData.value > 0) {
      if (!stripeConsumer || !stripeElements) {
        return;
      }
      const result = await stripeConsumer.confirmPayment({
        elements: stripeElements,
        redirect: "if_required",
        confirmParams: { return_url: window.location.href },
      });

      if (
        !result.errors &&
        result.paymentIntent &&
        result.paymentIntent.status === "succeeded"
      ) {
        sendData(
          getValues(),
          undefined,
          undefined,
          undefined,
          result.paymentIntent.id,
          result.paymentIntent.client_secret
        );
      } else {
        setLoadingPayment(false);
        await handlePaymentErrors(result.error);
      }
    } else if (
      isZig() &&
      orderData.value > 0 &&
      YUNO_METHODS.includes(formData.payment.payment_type)
    ) {
      if (
        !CARD_METHODS.includes(formData.payment.payment_type) &&
        orderData.value > 0
      ) {
        setInitializingYuno(true);
        mountYunoCheckout();
      }

      await yuno.submitOneTimeTokenForm();
    } else {
      await sendData(formData);
    }
  }

  const createCheckoutSession = async (data) => {
    const orderInfo = getOrderInfo(data);

    return await checkoutApi
      .post("begin", {
        token: token,
        event_id: event.id,
        currency: currencyDictionary[event.currency],
        amount: orderInfo.value,
        seller_name: process.env.REACT_APP_CHECKOUT_SELLER_NAME,
        redirect_url: `${window.location.protocol}//${window.location.host}/processing?token=${token}`,
      })
      .then(({ data }) => data)
      .catch((error) => {
        sendToLogger(error);
        setInitializingYuno(false);
        setIsYunoInitialized(false);
      });
  };

  async function initCheckoutLite() {
    setIsYunoInitialized(true);

    const { session, raw_data } = await createCheckoutSession(data);

    const availableMethods = compareYunoPaymentMethodsWithSystem(
      raw_data,
      data.methods
    );

    if (!availableMethods.length) {
      setOrderData(data.methods[0]);
      setData({ ...data, methods: data.methods });
      setInitializingYuno(false);
      return;
    }
    const firstMethod = availableMethods[0];
    setOrderData(firstMethod);

    setData({ ...data, methods: availableMethods });

    if (firstMethod.value !== 0) {
      if (!session) {
        errorMessage(
          "Ocorreu um erro. Clique abaixo para ser redirecionado à página do evento e refazer a compra.",
          () => history.push(`/eventos/${localStorage.getItem("slug")}`)
        );
      } else {
        await yuno.startCheckout({
          checkoutSession: session,
          elementSelector: "#root-div",
          renderMode: {
            elementSelector: "#yuno-payment-form",
            type: "element",
          },
          card: {
            type: "extends",
            cardSaveEnable: false,
            styles: `
            [class*=Yuno-card-form__title] {
              display: none !important;
            }
            [class*=Yuno-card-icon-bar__content] {
              display: none !important;
            }
            [class*=Yuno-card-container].css-1sivy39  {
              width: 100% !important;
            }
            [class*=Yuno-card-container] {
              padding: 0 !important;
            }
            [class*=Yuno-text-field__label] {
              font-weight: bold !important;
            }
            [class*=Yuno-select-field__label] {
              font-weight: bold !important;
            }
            [class*=Yuno-card-form__text-security] {
              display: none !important;
            }`,
          },
          automaticallyUnmount: false,
          countryCode: userData?.countryCode || "BR",
          language: i18n.language,
          keepLoader: false,
          showLoading: false,
          showPaymentStatus: false,
          showPayButton: false,
          onLoading: (args) => {
            if (args.isLoading === true && args.type === "ONE_TIME_TOKEN") {
              setLoadingPayment(true);
            }
          },
          onRendered: () => {
            setInitializingYuno(false);
          },
          yunoCreatePayment: (oneTimeToken, tokenWithInformation) => {
            createPaymentYuno(oneTimeToken, tokenWithInformation, session);
          },
          yunoError: () => {
            yunoErrorHandler();
          },
        });
      }
    }
    setInitializingYuno(false);
  }

  const mountYunoCheckout = async () => {
    if (orderData.value > 0) {
      await yuno.mountCheckoutLite({
        paymentMethodType: yunoPaymentDictionary(watch("payment.payment_type")),
        vaultedToken: null,
      });

      yuno.startPayment();
    }
  };

  async function createPaymentYuno(
    oneTimeToken,
    tokenWithInformation,
    checkoutSession
  ) {
    const isValid = await trigger(undefined, { shouldFocus: true });

    if (isValid) {
      await sendData(
        getValues(),
        oneTimeToken,
        tokenWithInformation,
        checkoutSession
      );
    } else {
      setLoadingPayment(false);
    }
  }

  const yunoErrorHandler = async () => {
    const { session } = await createCheckoutSession(data);
    sessionStorage.setItem("newCheckoutSession", session);

    await yuno.updateCheckoutSession(session);

    await yuno.notifyError();
  };

  const yunoPaymentDictionary = (method) => {
    return yunoPaymentTypes[method];
  };

  const compareYunoPaymentMethodsWithSystem = (
    yunoPaymentMethods,
    systemMethods
  ) => {
    const yunoAvailableMethods = [
      ...new Set(yunoPaymentMethods?.data.providerData.map(({ type }) => type)),
    ];

    if (!yunoAvailableMethods) {
      return systemMethods;
    }

    return systemMethods
      .map((paymentMethod) => {
        if (
          paymentMethod.payment_mode === "zig" ||
          (!paymentMethod.payment_mode && isZig())
        ) {
          if (
            paymentMethod.method === "Google Pay" &&
            yunoAvailableMethods.includes("GOOGLE_PAY")
          ) {
            return paymentMethod;
          } else if (
            paymentMethod.method === "Apple Pay" &&
            yunoAvailableMethods.includes("APPLE_PAY")
          ) {
          } else if (
            CARD_METHODS.includes(paymentMethod.method) &&
            yunoAvailableMethods.includes("CARD")
          ) {
            return paymentMethod;
          }
        } else {
          return paymentMethod;
        }
        return null;
      })
      .filter((paymentMethod) => paymentMethod !== null);
  };

  const createStripeIntent = async (data) => {
    const orderInfo = getOrderInfo(data);

    checkoutApi
      .post("begin", {
        token,
        event_id: event.id,
        currency: event.currency,
        amount: orderInfo.value,
        seller_name: process.env.REACT_APP_CHECKOUT_SELLER_NAME,
      })
      .then(({ data }) => {
        setIntentInfo({ session: data.session });
        setStripeIsInitialized(true);
        setInitializingStripe(false);
      })
      .catch((error) => {
        sendToLogger(error);
      });
  };

  const getOrderInfo = (data) => {
    let orderInfo;
    if (watch("payment.payment_type")) {
      orderInfo = data.methods.find(
        ({ method }) => method === watch("payment.payment_type")
      );
      if (!orderInfo) {
        orderInfo = data.methods[0];
      }
    } else {
      orderInfo = data.methods[0];
    }
    return orderInfo;
  };

  if (!loadingPage && !data) {
    return null;
  }

  return (
    <>
      {window.location.pathname.includes("checkout") && (
        <CompleteUserRegistration />
      )}

      <Fragment>
        {loadingPage ? (
          <Fragment>
            <div className="loading-container"></div>
            <Loader></Loader>
          </Fragment>
        ) : (
          <Fragment>
            <MetaTags>
              {event.fb_domain_verification_code && (
                <meta
                  name="facebook-domain-verification"
                  content={event.fb_domain_verification_code}
                />
              )}
            </MetaTags>

            <NewLoading showModal={loadingPayment} title={t("Loading.almost")} />

            {(initializingYuno || initializingStripe) && (
              <Fragment>
                <Loader
                  title={t("Checkout.wait")}
                  text={t("Checkout.initializing")}
                />
              </Fragment>
            )}

            <CountDownView
              expiresIn={data.expires}
              onTimesUp={finalizeCardByTimeOut}
              secondsToExpires={data.time_to_expire}
            />

            <div
              id="page-checkout"
              className="container my-4"
              style={{ marginBottom: isInZigApp() ? "4.2rem" : "" }}
            >
              <Row>
                <Col id="checkout-title">
                  <h2 className="event-title">{event.name}</h2>
                  <div id="checkout-eventinfos">
                    <p className="mr-2 mb-0">
                      {t("CommonExpressions.orderNumber")}{" "}
                      <strong>{token}</strong>
                    </p>
                  </div>
                </Col>
              </Row>
              {showInformativeBox && (
                <DiscountDescriptionView
                  discountCoupon={discountCoupon}
                  integrationCouponDescription={integrationCouponDescription}
                  isUserElegibleForDiscount={isUserElegibleForDiscount}
                  translate={t}
                />
              )}

              <form onSubmit={handleSubmit(formHandlerSubmit)}>
                <Row className="mt-4">
                  <Col id="checkout" lg={8}>
                    <div>
                      <Row>
                        <Col>
                          <Element name="attendees" className="card">
                            <div className="card-header">
                              {t("Checkout.participantInformation")}
                            </div>
                            <div className="card-body">
                              {watch("attendees").map((item, idx) => (
                                <TicketDetailView
                                  key={item.id}
                                  user={user}
                                  index={idx}
                                  event={event}
                                  watch={watch}
                                  translate={t}
                                  errors={errors}
                                  attendee={item}
                                  register={register}
                                  onSetValue={setValue}
                                  clearErrors={clearErrors}
                                  formQuestions={formQuestions}
                                />
                              ))}
                            </div>
                          </Element>
                        </Col>
                      </Row>
                    </div>

                    <Row className="payment-row">
                      <Col>
                        <Element name="paymentInfos" className="card">
                          <div className="card-header">
                            <div className="d-inline-block" id="payment-info">
                              {orderData?.value > 0
                                ? t("Checkout.paymentInformation")
                                : t("Checkout.buyerInformation")}
                            </div>
                          </div>
                          <div className="card-body">
                            <fieldset>
                              <div role="group">
                                <PaymentMethodsView
                                  user={user}
                                  translate={t}
                                  isZig={isZig}
                                  watch={watch}
                                  errors={errors}
                                  isStripe={isStripe}
                                  register={register}
                                  orderData={orderData}
                                  onSetValue={setValue}
                                  clearErrors={clearErrors}
                                  dataMethods={data.methods}
                                  cardMethods={CARD_METHODS}
                                  addressMethods={ADDRESS_METHODS}
                                  onZipCodeFetched={setZipCodeFetched}
                                  mountYunoCheckout={mountYunoCheckout}
                                  onInitializingYuno={setInitializingYuno}
                                  hasAtleastOneZigPaymentMethod={hasAtLeastOneZigPayment()}
                                />
                              </div>
                            </fieldset>

                            <div
                              className={
                                !isStripe() &&
                                  watch("payment.payment_type") === "Cartão"
                                  ? "d-block"
                                  : "d-none"
                              }
                            >
                              <InstallmentsView
                                translate={t}
                                watch={watch}
                                errors={errors}
                                register={register}
                                installments={installments}
                              />
                            </div>
                          </div>
                        </Element>
                      </Col>
                    </Row>

                    {!isStripe() && orderData?.value > 0 && (
                      <Row>
                        <Col>
                          <Element
                            name="paymentBillingAddressInfo"
                            className="card"
                          >
                            <div className="card-header">
                              <div
                                className="d-inline-block"
                                id="billing-address-info"
                              >
                                {t("Checkout.billingAddress")}
                              </div>
                            </div>
                            <div className="card-body">
                              <BillingAddressView
                                watch={watch}
                                translate={t}
                                errors={errors}
                                register={register}
                                onSetValue={setValue}
                                clearErrors={clearErrors}
                                zipCodeFetched={zipCodeFetched}
                                fetchingZipCode={fetchingZipCode}
                                onZipCodeFetched={setZipCodeFetched}
                                onFetchingZipCode={setFetchingZipCode}
                              />
                            </div>
                          </Element>
                        </Col>
                      </Row>
                    )}

                    {CARD_METHODS.includes(watch("payment.payment_type")) && (
                      <Row>
                        <Col>
                          <CardPaymentFormView
                            user={user}
                            watch={watch}
                            isZig={isZig}
                            translate={t}
                            stripe={stripe}
                            errors={errors}
                            cardCvv={cardCvv}
                            register={register}
                            isStripe={isStripe}
                            cardType={cardType}
                            orderData={orderData}
                            onSetValue={setValue}
                            intentInfo={intentInfo}
                            clearErrors={clearErrors}
                            cardMethods={CARD_METHODS}
                            walletMethods={WALLET_METHODS}
                            onZipCodeFetched={setZipCodeFetched}
                            onStripeElements={setStripeElements}
                            onStripeConsumer={setStripeConsumer}
                            stripeIsInitialized={stripeIsInitialized}
                            onInitialingStripe={setInitializingStripe}
                          />
                        </Col>
                      </Row>
                    )}

                    <Row>
                      <Col>
                        <OrderSummaryView
                          orderData={orderData}
                          installmentList={installments}
                          discountCode={data.discount_code}
                          discountType={data.discount_type}
                          discountValue={data.discount_value}
                          installments={watch("payment.installments")}
                          paymentMethod={watch("payment.payment_type")}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <AcceptTermsView
                          checked={acceptTerms}
                          onChange={setAcceptTerms}
                        />
                      </Col>
                    </Row>

                    <Row className="justify-content-end my-4 d-md-none">
                      <Col>
                        <button
                          disabled={
                            !acceptTerms || fetchingZipCode || isSubmitting
                          }
                          className="btn order-submit-button btn-md w-100"
                          type="submit"
                        >
                          {t("Checkout.finishPurchase")}
                        </button>
                        <p className="safe-purchase d-flex justify-content-center align-items-center">
                          <img src={shield_security} alt="shield_security" />
                          {t("Checkout.safePurchase")}
                        </p>
                      </Col>
                    </Row>
                  </Col>

                  {!isInZigApp() && (
                    <Col lg={4} className="d-none d-lg-block">
                      <TicketsSummaryView
                        orderData={orderData}
                        acceptTerms={acceptTerms}
                        fetchingZipCode={fetchingZipCode}
                        isSubmitting={isSubmitting}
                      />
                    </Col>
                  )}
                </Row>
              </form>
            </div>
          </Fragment>
        )}
      </Fragment>

      <div id="root-div"></div>
    </>
  );
};

export default CheckoutNew;
