import React, { Fragment, useEffect, useState } from "react";
import { ReactComponent as Copy } from "../../../../../assets/svg/Copy.svg";
import { ReactComponent as Checked } from "../../../../../assets/svg/new_check.svg";
import NewButton from "../../../../../components/NewButton";
import { useTranslation } from "react-i18next";

function PixSection({ handleCopy, isCopy, handlePayment }) {
  const { t } = useTranslation();
  const [showPaidButton, setShowPaidButton] = useState(false)

  useEffect(() => {
    const interval = setInterval(async () => {
      setShowPaidButton(true)
    }, 40000);
  
    return () => clearInterval(interval); 
  }, [])
  return (
    <Fragment>
      <div className="col-12 row d-flex justify-content-center align-items-center checkout-informations pix-buttons">
        <div className="col-md-5 col-12 checkout-copy-button">
          <NewButton
            className="primary-contained pix-copy-btn"
            onClick={handleCopy}
            content={
              <Fragment>
                {isCopy ? (
                  <Fragment>
                    <Checked /> {t("CheckoutStatus.buttons.pixCopiedCode")}
                  </Fragment>
                ) : (
                  <Fragment>
                    <Copy stroke="white" /> {t("CheckoutStatus.buttons.pixCopyCode")}
                  </Fragment>
                )}
              </Fragment>
            }
          />
        </div>
        {showPaidButton && (
          <div className="col-md-5 col-12 checkout-copy-button">
          <NewButton
            className="secondary-contained pix-copy-btn"
            onClick={handlePayment}
            content={
              <Fragment>
                {t("CheckoutStatus.common.paymentDone")}
              </Fragment>
            }
          />
        </div>
        )}
      </div>
    </Fragment>
  );
}

export default PixSection;
