import React, { Fragment, useEffect, useState } from "react";
import { ReactComponent as Export } from "../../../../../assets/svg/external.svg";
import NewButton from "../../../../../components/NewButton";
import { useTranslation } from "react-i18next";
import { getSessionStorageItems } from "../../../../../services/utils";
import Countdown from "react-countdown";
import "./style.css";

export default function PicpaySection({ code, handleRedirect, handlePayment }) {
  const { t } = useTranslation();
  const [showPaidButton, setShowPaidButton] = useState(false);
  const [timer, setTimer] = useState(false);
  const { paymentDate } = getSessionStorageItems(["payment_date"]);

  useEffect(() => {
    const interval = setInterval(async () => {
      setShowPaidButton(true);
    }, 40000);

    const parsedPaymentDate = new Date(JSON.parse(paymentDate));
    parsedPaymentDate.setTime(parsedPaymentDate.getTime() + 3600000);
    setTimer(parsedPaymentDate);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      {code && (
        <div className="col-12 row d-flex justify-content-center align-items-center checkout-informations">
          <div className="col-md-6 col-12 checkout-copy-button">
            <NewButton
              className="primary-contained"
              onClick={() => handleRedirect("picpay")}
              content={
                <Fragment>
                  <Fragment>
                    {t("CheckoutStatus.buttons.picpay")}{" "}
                    <Export stroke="white" />
                  </Fragment>
                </Fragment>
              }
            />
          </div>
          {showPaidButton && (
            <div className="col-md-6 col-12 pl-1 checkout-copy-button">
              <NewButton
                className="secondary-contained"
                onClick={handlePayment}
                content={<Fragment>{t("CheckoutStatus.common.paymentDone")}</Fragment>}
              />
            </div>
          )}
          {timer && (
            <div className="mt-2 col-12 d-flex flex-row justify-content-center align-items-center expire-code">
              <p className="mr-2">{t("CheckoutStatus.common.codeExpire")}</p>
              <strong>
                <Countdown daysInHours zeroPadTime={1} date={timer} />
              </strong>
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
}
