/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from "dayjs";
import { group, objectify } from "radash";
import React, { Fragment, useEffect, useState } from "react";
import { Accordion, Card, Spinner } from "react-bootstrap";
import ReactPixel from "react-facebook-pixel";
import ReactGA4 from "react-ga4";
import MetaTags from "react-meta-tags";
import { useHistory, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Element, scroller } from "react-scroll";
import Swal from "sweetalert2";
import AuthenticationFlow from "../../components/Auth";
import Banner from "../../components/Banner";
import DiscountCoupon from "../../components/DiscountCoupon";
import EventGridContainer from "../../components/Events/EventGridContainer";
import Loader from "../../components/Loader";
import PreviewSeatChart from "../../components/PreviewSeatChart";
import Produtores from "../../components/Produtores";
import SmallSpinner from "../../components/SmallSpinner";
import { SoldOutModal } from "../../components/SoldOutModal";
import Sponsors from "../../components/Sponsor";
import sendToLogger from "../../Helpers/errorLogger";
import EventHelper from "../../Helpers/event";
import MaskHelper from "../../Helpers/mask";
import { pushDataLayerAndEvent, pushGAEvent } from "../../Helpers/tagManager";
import { useAuth } from "../../hooks/useAuth";
import { findDescription } from "../../services/constants";
import KondutoService from "../../services/konduto";
import NewApi from "../../services/new-api";
import { isInZigApp } from "../../services/utils";
import TicketListView from "../../views/event/TicketListView";
import SeatsChart from "../SeatsChart";
import { useTranslation } from "react-i18next";
import "./index.css";
import MoengageService from "../../services/moengage";
import { useEvent } from "../../hooks/useEvent";
import { useZendesk } from "../../hooks/useZendesk";

const newApi = new NewApi();
const EVENTS_PER_PAGE = 4;

export default function Index() {
  const { isLogged, user } = useAuth();
  const { show } = useZendesk();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [total, setTotal] = useState(0);
  const [codePromo, setCodePromo] = useState("");
  const [appliedCode, setAppliedCode] = useState(false);
  const [event, setEvent] = useState({});
  const [ingressos, setIngressos] = useState([]);
  const [ticketSectors, setTicketSectors] = useState([]);
  const [comissario, setComissario] = useState(null);
  const [counter, setCounter] = useState(0);
  const [openLogin, setOpenLogin] = useState(false);
  const [sectorOpened, setSectorOpened] = useState();
  const [enableToLoadTickets, setEnableToLoadTickets] = useState(false);
  const [maxDiscountUse, setMaxDiscountUse] = useState(null);
  const [isDiscountInvalidForAll, setIsDiscountInvalidForAll] = useState(
    ingressos.length > 0 && !ingressos.some((item) => item.discount !== null)
  );
  const [discountDescription, setDiscountDescription] = useState("");
  const [showSoldOutModal, setSoldOutModal] = useState(false);
  const [unavailableTickets, setUnavailableTickets] = useState([]);
  const [availableTickets, setAvailableTickets] = useState(false);

  //loadings
  const [loadingCart, setLoadingCart] = useState(false);
  const [loadingEvent, setLoadingEvent] = useState(false);
  const [loadingSuggestions, setLoadingSuggestions] = useState(true);
  const [loadingTickets, setLoadingTickets] = useState(false);
  const [loadingDiscount, setLoadingDiscount] = useState(false);
  const [chartThumbnail, setChartThumbnail] = useState("");
  const [showCart, setShowCart] = useState(false);
  const [codeUsed, setCodeUsed] = useState(false);
  const [isReadMore, setIsReadMore] = useState(false);
  const [events, setEvents] = useState([]);
  const [currentPage] = useState(0);
  const { events: defaultEventsSuggestions } = useEvent();
  const [sameProducer, setSameProducer] = useState(false);

  const { slug } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const stewardCode = urlParams.get("code");
  const urlDiscountCoupon = urlParams.get("cupom");
  const maxPerOrder = event.max_online_order_tickets;

  useEffect(() => {
    show();

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (window.location.href.match(/\/$/)) {
      window.location.replace(window.location.href.slice(0, -1));
    }

    setEvents([]);

    getEvent();
  }, [location]);

  useEffect(() => {
    if (event && event.fb_pixel_id) {
      ReactPixel.init(event.fb_pixel_id);

      trackingEventToPixel("ViewContent");
    }
  }, [event]);

  const getSuggested = async (event) => {
    const nextPage = currentPage + 1;
    setLoadingSuggestions(true);
    try {
      const res = await newApi.get(
        `events/?by_organization_slug=${event.producer.slug}&page=${nextPage}&per_page=${EVENTS_PER_PAGE}`
      );

      const events = res.data.data.filter((item) => item.id !== event.id);

      if (events.length > 0) {
        setEvents(events);
        setSameProducer(true);
      } else {
        setEvents(defaultEventsSuggestions.middle_highlights);
        setSameProducer(false);
      }
    } finally {
      setLoadingSuggestions(false);
    }
  };

  const closeLogin = () => {
    setOpenLogin(false);
  };

  const loadEventInformation = async (eventData) => {
    setEvent(eventData);

    setChartThumbnail(eventData.seats_integration_thumbnail);

    if (eventData.site_cart_loads_automatically) {
      setEnableToLoadTickets(true);
      await getTickets();
    }

    if (eventData.g_analytics_code) {
      ReactGA4.gtag("config", eventData.g_analytics_code);
    }

    KondutoService.sendEvent("page", "event");

    KondutoService.sendEvent(
      "product",
      `id=${eventData.slug},name=${eventData.name}`,
      "product"
    );

    if (eventData) {
      getSuggested(eventData);
    }
    const couponDescription = findDescription(
      urlDiscountCoupon || promoCode,
      eventData?.discount
    );

    if (couponDescription) {
      setDiscountDescription(couponDescription);
    }

    pushDataLayerAndEvent(handleEventTagManager(eventData, user));
    pushGAEvent("view_item");

    if (user) {
      await MoengageService.createEvent({
        type: "event",
        customer_id: user?.id,
        actions: [
          {
            action: "View item",
            attributes: {
              eventId: eventData.id,
            },
            platform: "web",
          },
        ],
      });
    }
  };

  const getEvent = async () => {
    setLoadingEvent(true);

    newApi
      .get(`events/${slug}`)
      .then(async (res) => {
        await loadEventInformation(res.data);
      })
      .catch((error) => {
        sendToLogger(error);
        Swal.fire({
          icon: "error",
          title: t("Event.eventUnavailable"),
          text: t("Event.eventUnavailableMessage"),
          didClose: () => history.push("/"),
        });
      })
      .finally(() => setLoadingEvent(false));
  };

  const processTickets = (tickets, steward, cart_discount) => {
    const aux = tickets.map((item) => ({
      available: item.available,
      name: item.name,
      value: item.value,
      discount: item.discount != null ? Number(item.discount) : null,
      qty: 0,
      id: item.id,
      sectorId: item.sector_id,
      fee: Number(item.fee),
      minimum: item.minimum,
      maximum: item.maximum || event.max_online_order_tickets,
      seats_category_id: item.seats_category_id,
      description: parseTicketDescription(item),
      is_passport: item.is_passport,
      passport_items_amount: item.passport_items_amount,
      amountByLot: item.amountByLot,
      eventSectorLotId: item.event_sector_lot_id,
      lotAvailable: item.lot_available,
    }));

    setTicketSectors(
      objectify(
        tickets,
        ({ sector_id }) => sector_id,
        ({ sector_name }) => ({ name: sector_name, qty: 0 })
      )
    );

    setIngressos(aux);
    setTotal(total - (steward?.has_discount ? cart_discount : 0));
  };

  const getTickets = async () => {
    setLoadingTickets(true);

    const ticketError = () => {
      Swal.fire({
        icon: "error",
        title: t("Event.ticketsUnavailable"),
        text: t("Event.ticketsUnavailableMessage"),
        didClose: () => history.push("/"),
      });
    };

    try {
      let ticketsResponse;
      const ticketParams = {};

      KondutoService.sendEvent("page", "basket");
      if (stewardCode) {
        ticketParams.s = stewardCode;
      }
      if (urlDiscountCoupon) {
        ticketParams.d = urlDiscountCoupon;
      }
      ticketsResponse = await newApi.get(`events/${slug}/tickets`, {
        params: ticketParams,
      });
      KondutoService.sendEvent("page", "basket");

      const {
        steward,
        availables,
        unavailables,
        cart_discount,
        integration_discount,
      } = ticketsResponse.data;

      const tickets = availables.concat(unavailables);

      if (integration_discount) {
        sessionStorage.setItem(
          "isElegible",
          JSON.stringify(integration_discount)
        );
      }

      if (event.seats_integration_id && event.seats_integration_chart_id) {
        setShowCart(true);
      }

      if (steward) {
        setComissario(steward);

        if (steward.has_discount) {
          setCodePromo(steward.name);
          setAppliedCode(steward.discount);
        }
      } else {
        const hasDiscount = tickets.some(
          (ticket) => ticket.discount !== undefined && ticket.discount !== null
        );

        if (hasDiscount) {
          setCodeUsed(true);
          setCodePromo(urlDiscountCoupon);
          setAppliedCode(true);
        }
      }

      processTickets(tickets, steward, cart_discount);
    } catch (error) {
      sendToLogger(error);

      if (error.response.data.message === "NotDesignatedSeatsIoCategory") {
        return Swal.fire({
          icon: "warning",
          title: t("Event.attention"),
          text: t("Event.seatsNotAvailable"),
        }).then(() => setShowCart(false));
      } else {
        ticketError();
      }
    } finally {
      setLoadingTickets(false);
      setLoadingEvent(false);
    }
  };

  const handleLoadTickets = async () => {
    setEnableToLoadTickets(true);
    await getTickets();
  };

  const onBuy = () => {
    const updatedTickets = ingressos.map((ingresso) => {
      if (unavailableTickets.includes(ingresso.name)) {
        return { ...ingresso, qty: 0 };
      }
      return ingresso;
    });
    setIngressos(updatedTickets);
    setSoldOutModal(false);
    sendCheckoutPage();
  };

  const sendCheckoutPage = (force = false) => {
    if (!isLogged && !force) {
      setOpenLogin(true);
      return;
    }
    setLoadingCart(true);

    let data = [];

    ingressos.forEach((item) => {
      if (item.qty > 0) {
        data.push({ id: item.id, quantity: item.qty });
      }
    });

    let query = {
      discount: codeUsed && codePromo !== "" ? codePromo : null,
      steward: stewardCode ? stewardCode : null,
      cart: data,
      channel: isInZigApp() ? "zigapp" : "site",
    };

    newApi
      .post(`/events/${event.slug}/orders`, query)
      .then(async (res) => {
        trackingEventToPixel("AddToCart", 0);
        trackingEventToPixel("InitiateCheckout", 0);
        pushGAEvent("add_to_cart", {
          currency: "BRL",
          value: total,
          items: ingressos
            .filter(({ qty }) => qty > 0)
            .map((item) => ({
              item_id: item.id,
              item_name: item.name,
              price: item.value + item.fee,
              coupon: query.discount,
              quantity: item.qty,
              discount: item.discount ? item.value - item.discount : 0,
            })),
        });

        await MoengageService.createEvent({
          type: "event",
          customer_id: user?.id,
          actions: [
            {
              action: "Add to Cart",
              attributes: {
                currency: "BRL",
                value: total,
                items: ingressos
                  .filter(({ qty }) => qty > 0)
                  .map((item) => ({
                    item_id: item.id,
                    item_name: item.name,
                    price: item.value + item.fee,
                    coupon: query.discount,
                    quantity: item.qty,
                    discount: item.discount ? item.value - item.discount : 0,
                  })),
              },
              platform: "web",
            },
          ],
        });

        localStorage.setItem("slug", event.slug);
        history.push(`/checkout/${res.data.token}`);
      })
      .catch((err) => {
        sendToLogger(err);

        const { type, tickets, message } = err.response?.data || {};

        if (type === "sold_out_tickets") {
          const unavailableTickets = ingressos
            .filter((ingresso) => tickets.includes(ingresso.id.toString()))
            .map((ingresso) => ingresso.name)
            .join(", ");

          const availableTickets = ingressos.some(
            (ingresso) =>
              !tickets.includes(ingresso.id.toString()) && ingresso.qty > 0
          );

          setSoldOutModal(true);
          setAvailableTickets(availableTickets);
          setUnavailableTickets(unavailableTickets);
        } else {
          let errorMessage =
            type === "reach_ticket_by_document"
              ? `${t("Event.purchaseLimitReached")} ${tickets.join(", ")}.`
              : message;

          Swal.fire({
            icon: "error",
            title: t("FaleForm.oops"),
            text: errorMessage || t("Event.purchaseError"),
            didClose: () => {
              if (type === "reservation_ticket_error") {
                history.go(0);
              }
            },
          });
        }

        setLoadingCart(false);
      });
  };

  //aplica código promocional
  const promoCode = async (code) => {
    setLoadingDiscount(true);
    setIsDiscountInvalidForAll(false);

    await newApi
      .get(`/events/${event.slug}/tickets?d=${code}`)
      .then((res) => {
        const { availables, unavailables, cart_discount, max_discount_use } =
          res.data;
        let aux = [];
        let total = 0;
        let cartTicketQuantity = 0;

        availables.concat(unavailables).forEach((item, index) => {
          aux.push({
            available: item.available,
            name: item.name,
            value: item.value,
            discount:
              item.discount !== undefined && item.discount !== null
                ? Number(item.discount)
                : null,
            qty: ingressos[index]?.qty,
            id: item.id,
            sectorId: item.sector_id,
            fee: Number(item.fee),
            minimum: item.minimum,
            maximum: item.maximum || event.max_online_order_tickets,
            description: parseTicketDescription(item),
          });
        });
        setMaxDiscountUse(max_discount_use);

        cartTicketQuantity += aux.reduce((acc, item) => acc + item.qty, 0);

        if (code && cartTicketQuantity > max_discount_use) {
          for (let i = 0; i < aux.length; i++) {
            const currentItem = aux[i];

            while (
              currentItem.qty > 0 &&
              cartTicketQuantity > max_discount_use
            ) {
              currentItem.qty -= 1;
              cartTicketQuantity -= 1;
            }
          }
          setCounter(cartTicketQuantity);

          Swal.fire({
            title: t("Event.attention"),
            icon: "warning",
            text: t("Event.discountLimitation"),
          });
        }

        aux.forEach((item) => {
          if (Number(item.discount >= 0 && item.discount !== null)) {
            total += (Number(item.discount) + (item.fee || 0)) * item.qty;
          } else {
            total += (Number(item.value) + (item.fee || 0)) * item.qty;
          }
        });

        setTotal(total - cart_discount);

        if (aux.some((item) => item.discount !== null)) {
          setCodeUsed(true);
        } else {
          setIsDiscountInvalidForAll(true);
        }

        setCodePromo(code);
        setAppliedCode(code);
        setIngressos(aux);
      })
      .finally(() => {
        setLoadingDiscount(false);
      });
  };

  function trackingEventToPixel(title, timeout = 3000) {
    setTimeout(() => {
      ReactPixel.pageView();

      if (event?.fb_pixel_id) {
        ReactPixel.trackSingle(event.fb_pixel_id, title);
      }

      if (process.env.REACT_APP_PIXEL_ID) {
        ReactPixel.trackSingle(process.env.REACT_APP_PIXEL_ID, title);
      }
    }, timeout);
  }

  function parseTicketDescription(ticket) {
    let description = ticket.description || "";

    if (ticket.limited_by_document) {
      description = `${t("Event.limited")} ${ticket.maximum} ${t(
        "Event.ticketsByUser"
      )} ${description ? `<br/><br/>${description}` : description}`;
    }

    return description;
  }

  function handleTicketChange(ticket, mode) {
    if (mode === "plus") {
      if (ticket.qty > 0) {
        setCounter(counter - ticket.minimum);
        ticket.qty -= Number(ticket.minimum);

        if (ticket.discount >= 0 && ticket.discount !== null) {
          setTotal(
            total -
              (Number(ticket.discount) + (ticket.fee || 0)) * ticket.minimum
          );
        } else {
          setTotal(total - (ticket.value + (ticket.fee || 0)) * ticket.minimum);
        }
      }
    } else {
      ticket.qty += Number(ticket.minimum);
      setCounter(counter + ticket.minimum);

      if (ticket.discount >= 0 && ticket.discount !== null) {
        setTotal(
          total + (Number(ticket.discount) + (ticket.fee || 0)) * ticket.minimum
        );
      } else {
        setTotal(total + (ticket.value + (ticket.fee || 0)) * ticket.minimum);
      }
    }

    setIngressos([...ingressos]);

    const groupedTickets = group(ingressos, ({ sectorId }) => sectorId);

    Object.values(groupedTickets).forEach(
      (tickets) =>
        (ticketSectors[tickets[0].sectorId].qty = tickets.reduce(
          (acc, item) => acc + item.qty,
          0
        ))
    );
  }

  function handleSectorToogle(id) {
    if (sectorOpened === id) {
      setSectorOpened(null);
    } else {
      setSectorOpened(id);
    }
  }

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const handleEventTagManager = (data, user) => {
    return {
      tkt_event_name: data.name,
      event_local: data.event_location.name,
      event_produced_by: data.producer.name,
      event_city: data.event_location.city,
      event_date: dayjs(data.start_date).format("DD/MM/YYYY"),
      event_age_rating: data.age_range,
      event_local_not_user:
        !user || !user?.address?.city
          ? undefined
          : user?.address?.city !== data.event_location.city,
    };
  };

  return (
    <div className={`${showCart ? "" : "detail-event"} d-flex flex-column`}>
      {loadingEvent && (
        <Fragment>
          <div className="loading-container"></div>
          <Loader />
        </Fragment>
      )}
      {showCart ? (
        <SeatsChart
          tickets={ingressos}
          onSetTickets={setIngressos}
          event={event}
          onShowCart={setShowCart}
          isCodeApplied={appliedCode}
          onAppliedCode={setAppliedCode}
          isDiscountInvalidForAll={isDiscountInvalidForAll}
          onDiscountInvalidForAll={setIsDiscountInvalidForAll}
          isLoggedAndElegible={!loadingDiscount && appliedCode}
        />
      ) : (
        <Fragment>
          {Object.keys(event).length > 0 && (
            <>
              <MetaTags>
                {event.fb_domain_verification_code && (
                  <meta
                    name="facebook-domain-verification"
                    content={event.fb_domain_verification_code}
                  />
                )}
              </MetaTags>

              <Banner
                event={event}
                onPlaceClick={() =>
                  scroller.scrollTo("location", { smooth: true })
                }
              />

              <div id="event-content" className="d-inline-block container">
                <div className="content row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2">
                  <div id="event-description" className="col-lg-8">
                    <div id="event-header">
                      <span>
                        <i className="far fa-calendar-check"></i>
                        {EventHelper.buildPeriod(event)}
                      </span>
                      <span>
                        {event.is_online
                          ? "Online"
                          : event.event_location.formatted_address}
                      </span>
                    </div>

                    <hr></hr>

                    <div
                      className={`order-2 ${event.is_closed ? "text" : ""} ${
                        event.is_closed && isReadMore
                          ? "position-relative read-more"
                          : ""
                      }`}
                    >
                      <p
                        dangerouslySetInnerHTML={{
                          __html: event.description,
                        }}
                      />
                    </div>
                    {event.is_closed && event.description && (
                      <span
                        onClick={toggleReadMore}
                        className="d-block position-relative text-center read-or-hide"
                      >
                        <i
                          className={`mr-2
                            ${
                              isReadMore
                                ? "fas fa-chevron-up"
                                : "fas fa-chevron-down"
                            }`}
                        ></i>
                        {isReadMore ? t("Event.seeLess") : t("Event.seeMore")}
                      </span>
                    )}
                  </div>

                  {!event.is_closed &&
                  ((!event.site_cart_loads_automatically &&
                    !enableToLoadTickets) ||
                    !!event.seats_integration_id) ? (
                    <div className="col-lg-4 mt-md-0 text-center">
                      {comissario?.name && (
                        <span id="cart-steward">
                          {t("Event.soldBy")} {comissario.name}
                        </span>
                      )}
                      <button
                        className="btn btn-primary w-100"
                        onClick={handleLoadTickets}
                      >
                        {t("Event.buyTickets")}
                      </button>
                    </div>
                  ) : (
                    <>
                      <div id="event-sidebar" className="col-lg-4 mt-md-0">
                        <div id="cart-sidebar" className="shadow-sm">
                          {loadingTickets ? (
                            <div
                              className="d-flex justify-content-center align-items-center"
                              style={{ minHeight: 180 }}
                            >
                              <SmallSpinner />
                            </div>
                          ) : (
                            <>
                              <div className="ticket row">
                                {comissario && (
                                  <>
                                    <span id="cart-steward">
                                      {t("Event.soldBy")} {comissario.name}
                                    </span>
                                  </>
                                )}
                                {ingressos.length > 0 && (
                                  <h4>{t("Event.availableTickets")}</h4>
                                )}
                                <div id="ticket-info" className="col-12">
                                  {ingressos.length === 0 ? (
                                    <>
                                      <p className="unavailable-tickets">
                                        {t(
                                          "Event.ticketsUnavailableAtTheMoment"
                                        )}
                                      </p>
                                      <a
                                        href={`/organizacoes/${event.producer.slug}`}
                                        className="d-flex justify-content-center"
                                      >
                                        {t("Event.otherEventsProducer")}
                                      </a>
                                    </>
                                  ) : (
                                    <>
                                      {event.group_tickets_into_sectors ? (
                                        <Accordion className="sectors-accordion">
                                          {Object.keys(ticketSectors)
                                            .sort((a, b) => {
                                              const sectorA =
                                                event.event_sectors.find(
                                                  (sector) => sector.id === +a
                                                );
                                              const sectorB =
                                                event.event_sectors.find(
                                                  (sector) => sector.id === +b
                                                );
                                              return (
                                                sectorA.sector_index -
                                                sectorB.sector_index
                                              );
                                            })
                                            .map((id) => (
                                              <Card key={id}>
                                                <Accordion.Toggle
                                                  as={Card.Header}
                                                  eventKey={id}
                                                  className={
                                                    sectorOpened === id
                                                      ? "card-header-opened"
                                                      : ""
                                                  }
                                                  onClick={() =>
                                                    handleSectorToogle(id)
                                                  }
                                                >
                                                  <span>
                                                    {ticketSectors[id].name}
                                                  </span>
                                                  <div className="status">
                                                    {ticketSectors[id].qty >
                                                      0 && (
                                                      <div className="sectorAmount">
                                                        {ticketSectors[id].qty}
                                                      </div>
                                                    )}
                                                  </div>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse
                                                  eventKey={id}
                                                >
                                                  <Card.Body>
                                                    <TicketListView
                                                      selectedTicketsAmount={
                                                        ingressos.length
                                                      }
                                                      tickets={ingressos.filter(
                                                        ({ sectorId }) =>
                                                          sectorId.toString() ===
                                                          id
                                                      )}
                                                      maxDiscountUse={
                                                        maxDiscountUse
                                                      }
                                                      ticketsQuantity={counter}
                                                      maxTicketsPerOrder={
                                                        maxPerOrder
                                                      }
                                                      onChangeQuantities={
                                                        handleTicketChange
                                                      }
                                                      isLoadingDiscount={
                                                        loadingDiscount
                                                      }
                                                      isCodeApplied={
                                                        appliedCode
                                                      }
                                                    />
                                                  </Card.Body>
                                                </Accordion.Collapse>
                                              </Card>
                                            ))}
                                        </Accordion>
                                      ) : (
                                        <TicketListView
                                          tickets={ingressos}
                                          ticketsQuantity={counter}
                                          maxDiscountUse={maxDiscountUse}
                                          maxTicketsPerOrder={maxPerOrder}
                                          onChangeQuantities={
                                            handleTicketChange
                                          }
                                          isLoadingDiscount={loadingDiscount}
                                          isCodeApplied={appliedCode}
                                        />
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                              <div id="cart-resume">
                                {ingressos.length > 0 && (
                                  <>
                                    {(event.has_discount ||
                                      comissario?.discount) && (
                                      <div className="discount-component-container p-3">
                                        <DiscountCoupon
                                          stewardCode={stewardCode}
                                          tickets={ingressos}
                                          onPromoCode={promoCode}
                                          onLoading={setLoadingDiscount}
                                          isLoading={loadingDiscount}
                                          isCodeApplied={appliedCode}
                                          onAppliedCode={setAppliedCode}
                                          isDiscountInvalidForAll={
                                            isDiscountInvalidForAll
                                          }
                                          discountCouponDescription={
                                            discountDescription
                                          }
                                          urlCoupon={urlDiscountCoupon}
                                        />
                                      </div>
                                    )}

                                    <div
                                      id="cart-amount"
                                      className={"cart-amount p-3"}
                                    >
                                      {t("Event.totalValue")}{" "}
                                      <span>
                                        {MaskHelper.numberToLocaleCurrency(
                                          total
                                        )}
                                      </span>
                                    </div>
                                  </>
                                )}

                                {total > 0 || (total === 0 && counter > 0) ? (
                                  <button
                                    onClick={() => sendCheckoutPage()}
                                    disabled={loadingCart || loadingDiscount}
                                    type="submit"
                                    className="m-2 bt-checkout btn btn-primary btn btn-primary fadeIn"
                                  >
                                    {loadingCart || loadingDiscount ? (
                                      <Spinner
                                        animation="border"
                                        variant="light"
                                        size="sm"
                                        style={{ marginTop: 4 }}
                                      />
                                    ) : (
                                      <span>{t("Event.goToPayment")}</span>
                                    )}
                                  </button>
                                ) : (
                                  ""
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>

              {loadingSuggestions ||
                (!loadingSuggestions && events && (
                  <div id="main-events" className="container">
                    <div id="suggestions" className="events-list">
                      <div className="d-flex justify-content-center px-sm-1 see-more-gap">
                        <h2>
                          {sameProducer
                            ? t("Event.eventsSameProducer")
                            : t("Event.moreEventsForYou")}
                        </h2>
                      </div>
                      {loadingSuggestions && (
                        <>
                          <div className="see-more-loading"></div>
                          <Loader
                            style={{
                              width: "100%",
                              height: "100%",
                              backgroundColor: "#f8f9faa8",
                              position: "relative",
                              top: 0,
                              right: 0,
                              paddingTop: 10,
                              paddingBottom: 20,
                            }}
                          ></Loader>
                        </>
                      )}
                      <div className="mb-5 event-list-row">
                        <EventGridContainer events={events} />
                      </div>
                    </div>
                  </div>
                ))}

              {event.sponsors && (
                <div id="main-events" className="container">
                  <div className="row justify-content-around">
                    <Sponsors sponsors={event.sponsors} />
                  </div>
                </div>
              )}

              {event.seats_integration_id?.length > 0 && (
                <PreviewSeatChart
                  chartThumbnail={chartThumbnail}
                  callBackSeat={handleLoadTickets}
                />
              )}

              <Element name="location" style={{ margin: "-32px 0" }}>
                <Produtores
                  event={event}
                  location={event.event_location}
                  user={user}
                />
              </Element>

              <AuthenticationFlow
                show={openLogin}
                nextToUrl={window.location.href}
                onClose={closeLogin}
                onLoginCallback={() => sendCheckoutPage(true)}
              />
              <SoldOutModal
                show={showSoldOutModal}
                onClose={() => setSoldOutModal(false)}
                unavailableTickets={unavailableTickets}
                moreThanOnTickets={availableTickets}
                onBuy={onBuy}
              />
            </>
          )}
        </Fragment>
      )}
    </div>
  );
}
